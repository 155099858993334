import type { Companies, CompanySubscriptions } from "@condoit-apps/database";
import type { User } from "@condoit-apps/database/src/includes";
import { SubscriptionType } from "@prisma/client";
import { filter, find } from "lodash-es";

import { getCurrentUser } from "@/apiCalls/user/usersCRUD";
import type { ISession } from "@/services/auth.server";
import type React from "react";
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

interface CurrentUserContextType {
  loadingContext: boolean;
  currentUser: User | null;
  currentCompany: Companies | null;
  companySubscriptions: CompanySubscriptions[] | null;
  maxSeatCount: number | null;
  companyIsOnTrial: boolean;
  hasActiveSubscription: boolean;
  activeSubscription: CompanySubscriptions | null;
  hasFlexSubscription: boolean;
  isSuperAdmin: boolean;
  validSubscription: boolean;
  hasEnterpriseSubscription: boolean;
  isDemoAccount: boolean;
  session: ISession | null;
  refreshUserContext: () => Promise<{ user; company; subscriptions }>;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
  loadingContext: false,
  currentUser: null,
  currentCompany: null,
  companySubscriptions: null,
  maxSeatCount: 0,
  companyIsOnTrial: false,
  hasActiveSubscription: false,
  activeSubscription: null,
  hasFlexSubscription: false,
  isSuperAdmin: false,
  validSubscription: true,
  hasEnterpriseSubscription: false,
  isDemoAccount: false,
  session: null,
  refreshUserContext: async () => {
    return { user: null, company: null, subscriptions: [] };
  },
});

interface ProviderProps {
  // authData: CognitoUser;
  user: User | null;
  company: Companies | null;
  subscriptions: CompanySubscriptions[] | null;
  session: ISession | null;
  children: React.ReactNode;
}

const notProtectedRoutes: string[] = ["/auth/signout", "/auth", "/start", "/link", "/tag", "/share"];
export function isProtectedRoute(pathname: string | null) {
  if (pathname === null) return false;
  return find(notProtectedRoutes, (route) => pathname.startsWith(route)) === undefined;
}

export const CurrentUserProvider = ({ children, user, company, subscriptions, session }: ProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(user);
  const [loadingContext, setLoadingContext] = useState(false);
  const _activeSubscription = find(subscriptions, (sub) => sub.isActive) as CompanySubscriptions;
  const [currentSubscription, setCurrentSubscription] = useState<CompanySubscriptions | null>(_activeSubscription);
  const [currentCompany, setCurrentCompany] = useState<Companies | null>(company);
  const [currentSubscriptions, setCurrentSubscriptions] = useState<any>(subscriptions);
  const location = useLocation();
  const navigate = useNavigate();
  // // Computed
  const activeCompanySubscriptions =
    filter(currentSubscriptions, (subscription) => subscription.isActive === true) || [];

  const companyIsOnTrial = activeCompanySubscriptions?.some(
    (subscription) => subscription.type === SubscriptionType.Trial,
  );

  const maxSeatCount = activeCompanySubscriptions?.reduce((total, subscription) => total + subscription.seatCount, 0);

  const isDemoAccount = activeCompanySubscriptions.some((subscription) => subscription.type === SubscriptionType.Demo);

  const hasActiveSubscription = activeCompanySubscriptions?.length > 0;

  const hasFlexSubscription = activeCompanySubscriptions.some(
    (subscription) => subscription.type === SubscriptionType.Flex,
  );

  const hasEnterpriseSubscription = activeCompanySubscriptions.some(
    (subscription) => subscription.type === SubscriptionType.Enterprise,
  );

  const isSuperAdmin = currentUser?.isSuperAdmin || ((currentUser?.isAdmin && currentCompany?.isCondoit) as boolean);

  const [validSubscription, setValidSubscription] = useState(hasActiveSubscription);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (company) {
      setCurrentCompany(company);
    }
  }, [company]);

  useEffect(() => {
    if (subscriptions) {
      setCurrentSubscriptions(subscriptions);
    }
  }, [subscriptions]);

  const fetchUser = async (): Promise<{
    user: null;
    company: null;
    subscriptions: [];
  }> => {
    try {
      const startTime = Date.now();
      console.log("Fetching User");
      const { user, company, subscriptions } = await getCurrentUser();

      setLoadingContext(true);
      if (!user) {
        throw new Error("Current user not found");
      }

      if (!company) {
        throw new Error("Current company not found");
      }

      setCurrentUser(user);
      setCurrentCompany(company);
      setCurrentSubscriptions(subscriptions);
      const _activeSubscription = subscriptions.find((subscription) => subscription.isActive === true);
      setCurrentSubscription(_activeSubscription);

      const activeCompanySubscriptions = filter(subscriptions, (subscription) => subscription.isActive === true) || [];

      setValidSubscription(activeCompanySubscriptions?.length > 0);

      const endTime = Date.now();
      console.log("User Context fetched in: ", endTime - startTime, "ms");

      return { user, company, subscriptions };
    } catch (error) {
      console.log("error fetching user from server: ", error);
      return { user: null, company: null, subscriptions: [] };
    } finally {
      setLoadingContext(false);
    }
  };

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        currentCompany,
        companySubscriptions: currentSubscriptions,
        maxSeatCount,
        companyIsOnTrial,
        hasActiveSubscription,
        hasFlexSubscription,
        activeSubscription: currentSubscription,
        refreshUserContext: fetchUser,
        validSubscription: validSubscription,
        isSuperAdmin,
        loadingContext,
        hasEnterpriseSubscription,
        isDemoAccount,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
